import { STiRPreferences } from "../models";

export class StaticFields {
  static getYears(): string[] {
    const curYear = (new Date()).getFullYear();
    const years: string[] = [];
    years.push((curYear + 1).toString());
    years.push(curYear.toString());
    years.push((curYear - 1).toString());
    years.push((curYear - 2).toString());
    years.push((curYear - 3).toString());
    years.push((curYear - 4).toString());
    return years;
  }

  static userInfo: any;
  static homeScreenData: any = null;
  static userDetail: any;
  static appUserId: any;
  static email: any;
  static stirScreenData: any;
  static stirAltScreenData: any;
  static stirPreference: STiRPreferences = null;
  static years: string[] = this.getYears();
}